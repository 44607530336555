$darkDefault: #333333;
$darkStrong: #2e2d2d;

// darkDefault Tes
body.dark {
    background: $darkDefault;
    .mainNavbar {
        background: $darkDefault;
        color: $lightGray;
    }
    .navbar-brand {
        position: relative;
        border-radius: 5px;
        transition: 0.3s ease;
        &::after {
            content: " ";
            display: table;
            width: 100%;
            height: 35px;
            background: $darkDefault url("../../images/logoWhite.svg") no-repeat center center;
            background-size: 100%;
            position: absolute;
            top: 8px;
            left: 0;
        }
    }
    .headerHome {
        background: $darkDefault;
    }
    .mainNavbarOnScroll {
        background: $darkDefault;
    }
    #navbarScroll {
        a {
            color: $lightGray;
        }
    }

    // Mobile Nav Container
    .mobileNavContainer {
        background: $darkDefault;
        transition: 0.2s ease;
        &.openMobileNav {
            opacity: 1;
            left: 0%;
        }
        &.closeMobileNav {
            opacity: 0;
            left: -100%;
        }
        .header {
            background: $darkDefault;
            .logoMobile {
                background: blue !important;
                position: relative;
                width: 119px;
                height: 35;
                display: table;
                content: " ";
                img {
                    display: none !important;
                }
            }
        }
        ul {
            li {
                a {
                    color: $lightGray;
                    display: table;
                    border-color: $darkStrong;
                }
            }
        }
    }

    .btnToggleMenu {
        border-radius: 100px;
        border: none;
        width: 40px;
        height: 40px;
        transition: 0.3s ease;
        padding: 0px;
        i {
            font-size: 35px;
            color: $lightGray;
        }
        &:hover {
            @extend .shadow;
        }
    }

    .headerHome {
        background: url("../../images/bgHeader-dark.svg") no-repeat left center;
        .arrowDown {
            background: $primary;
            color: white;
        }
        .content {
            h1 {
                span {
                    color: $lightGray;
                }
            }
            h4 {
                color: $lightGray;
            }
        }
    }

    // Content Group
    .contentGroup {
        padding-top: 70px;
        h2 {
            position: relative;
            display: table;
            font-size: 3em;
            color: $lightGray;
            @include media-breakpoint-down(sm) {
                font-size: 2em;
            }
            p {
                color: $lightGray;
                opacity: 0.5;
            }
            span {
                font-family: $fontBlack;
                display: table;
                position: absolute;
                top: -60px;
                font-size: 0.9em;
                right: 0;
                transform: rotate(-10deg);
            }
        }
        &.alignRight {
            text-align: right;
            h2 {
                margin-left: auto;
            }
            p {
                color: $lightGray;
                opacity: 0.5;
            }
        }
    }
    // Content Group

    // Section About
    .sectionAbout {
        background: $darkDefault url("../../images/bgAboutHomepage-dark.svg") no-repeat left center;
        min-height: 90vh;
        display: flex;
        margin: 0em 0 0em 0;
        align-items: center;
        position: relative;
        @include media-breakpoint-down(md) {
            // background: blue;
            margin-top: 60px;
            padding-top: 70px;
        }
        .circlePrimary {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 1;
            @include media-breakpoint-down(md) {
                width: 50%;
                top: 50px;
            }
        }
        .item {
            display: flex;
            z-index: 10;
            @include media-breakpoint-down(md) {
                flex-direction: column-reverse;
            }
            .content {
                padding: 0 7em;
                @include media-breakpoint-down(md) {
                    padding: 0 30px;
                    margin-top: -240px;
                    z-index: 11;
                    h2 {
                        margin-bottom: 60px;
                        color: $lightGray;
                    }
                    p {
                        color: $lightGray;
                        opacity: 0.5;
                    }
                }
            }
            .image {
                text-align: right;
                .imgAbout {
                    background: $darkDefault url("../../images/imageAbout.png") no-repeat center center;
                    width: 700px;
                    height: 440px;
                    display: table;
                    content: " ";
                    border: 0;
                    border-top-left-radius: 100px;
                    border-top-right-radius: 0px;
                    border-bottom-left-radius: 40px;
                    border-bottom-right-radius: 0px;
                    @include media-breakpoint-down(sm) {
                        height: 300px;
                    }
                    @include media-breakpoint-down(md) {
                        background-size: cover;
                        width: 90%;
                        margin-top: 30px;
                        height: 500px;
                        margin-left: auto;
                        position: relative;
                        &::after {
                            display: table;
                            content: "";
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            border-bottom-left-radius: 40px;
                            border-bottom-right-radius: 0px;
                            background: -moz-linear-gradient(top, rgba(51, 51, 51, 0) 0%, rgba(51, 51, 51, 1) 78%);
                            background: -webkit-linear-gradient(top, rgba(51, 51, 51, 0) 0%, rgba(51, 51, 51, 1) 78%);
                            background: linear-gradient(to bottom, rgba(51, 51, 51, 0) 0%, rgba(51, 51, 51, 1) 78%);
                            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00333333', endColorstr='#333333',GradientType=0 );
                        }
                    }
                }
            }
        }
    }
    // Section About

    // Section Do Something
    .sectionDoSomething {
        min-height: 100vh;
        background: url("../../images/bgDoSomething-dark.svg") no-repeat left center;
        background-size: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 8em;
        @include media-breakpoint-down(md) {
            background-size: 90%;
            min-height: 60vh;
        }
        h2 {
            display: table;
            position: relative;
            font-family: $fontBlack;
            font-size: 5em;
            color: $lightGray;
            transform: rotate(-15deg);
            @include media-breakpoint-down(sm) {
                font-size: 9vw;
            }
            &::before {
                display: table;
                content: "";
                background: $primary;
                width: 50px;
                height: 50px;
                position: absolute;
                top: 0;
                left: -50px;
                border-radius: 50%;
            }
        }
    }
    // Section Do Something

    // Section service product
    .sectionServiceProduct {
        .item {
            img {
                opacity: 0.05;
            }
            .content {
                padding: 60px;
                h2 {
                    color: $primary;
                }
                p {
                    color: $lightGray;
                    opacity: 0.5;
                }
            }
        }
    }
    // Section service product

    // Section Choose us
    .sectionChooseUs {
        background: url("../../images/bgEllo-dark.svg") no-repeat left center;
        color: $lightGray;
        p {
            opacity: 0.5;
        }
    }
    // Section Choose us

    // Just Talk
    .sectionJustTalk {
        background: $darkDefault url("../../images/bgHii.svg") no-repeat center left;
        background-size: contain;
        color: $lightGray;
        padding: 11em 0;
        p {
            color: $lightGray;
            opacity: 0.5;
        }
        @include media-breakpoint-down(sm) {
            padding: 7em 30px 3em 30px;
            background-size: 60%;
            background-position: -50% -30%;
        }
    }
    // Just Talk

    // Section Portfolio
    .sectionPortfolio {
        background: $darkDefault url("../../images/bgPortfolio-dark.svg") no-repeat left top;
        .carousel {
            .carousel-indicators {
                li {
                    &.active {
                        background-color: $primary;
                    }
                }
            }
            .carousel-control-prev,
            .carousel-control-next {
                display: none;
            }
        }
        h2 {
            color: $lightGray;
        }
        .listPortfolio {
            li {
                .item {
                    background: $darkStrong url("../../images/bgCardDark.svg") no-repeat top left;
                    strong {
                        color: $lightGray;
                    }
                    p {
                        color: $lightGray;
                        opacity: 0.5;
                    }
                    &:hover {
                        @extend .shadow;
                        border-radius: 40px;
                    }
                }

                &:nth-child(even) {
                    .item {
                        background: #444444 url("../../images/bgCardDark.svg") no-repeat top left;
                        background-size: inherit;
                        color: $lightGray;
                        p {
                            opacity: 0.5;
                        }
                    }
                }
            }
        }
    }
    // Section Portfolio

    // Footer
    .footer {
        background: $darkStrong;
        color: $lightGray;
        .logoFooter {
            display: none;
        }
        p {
            opacity: 0.5;
        }
    }
    // Footer

    // Button Whatsapp mobile
    .btnWhatsappMobile {
        background: $primary;
        display: none;
        border-color: $primary;
        @include media-breakpoint-down(sm) {
            display: block;
        }
        &:hover {
            @extend .shadow-lg;
            width: 100px;
            height: 100px;
            font-size: 35px;
            bottom: 30px;
            right: 30px;
            i {
                margin-top: -40px;
            }
        }
    }
    // Button Whatsapp mobile

    // Button darkmode mobile
    .btn-darkmodeMobile {
        @include media-breakpoint-down(sm) {
            display: block;
        }
        display: none;
        background: $darkStrong !important;
        margin-right: -90px;
        color: $lightGray;
        border-color: $darkStrong;
    }
    // Button darkmode mobile
}
