.btn-transparent{
    background: transparent;
    border-color: transparent;
    &:hover{
        background: transparent;
        border-color: transparent;
        color: $primary;
    }
}
.btn-circle{
    border-radius: 200px;
}
.btn-wa{
    border-radius: 50%;
    width: 47px;
    height: 47px;
    padding: 0;
    background: $lightGray;
    border-color: #CED8DF;
    color: $text;
}
.btn-lg{
    font-size: 18px;
}
.btn-more-arrow{
    padding-left: 30px;
    padding-right: 30px;
    color: white;
    i{margin-left: 25px;}
    &:hover{
        background: $secondary;
        border-color: $secondary;
        color: white;
        @extend .shadow ;
    }
}