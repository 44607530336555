@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css");
$fontBlack: "BlackVosten";
body,
html {
    font-family: "Noto Sans", sans-serif;
    color: theme-color("text");
    scroll-behavior: smooth;
    font-size: 18px;
}

// Main Nav Bar Class
#mainNavbar {
    transition: 0.3s ease;
    .navbar-brand{
        transition: 0.3s ease;
    }
    .navbar-nav {
        a {
            font-size: 18px;
            margin: 0 15px;
        }
    }
}
.mainNavbar {
    background: white;
    padding-top: 25px;
    padding-bottom: 25px;
    transition: 0.4s ease;
}
.mainNavbarOnScroll {
    background: white;
    padding-top: 10px;
    padding-bottom: 10px;
    transition: 0.4s ease;
    @extend .shadow-lg;
}
// Main Nav Bar Class

// Header Home
.headerHome {
    background: white url("../../images/bgHeader.svg") no-repeat left center;
    background-size: 90%;
    min-height: 650px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    @include media-breakpoint-down(sm) {
        min-height: 400px;
    }
    @include media-breakpoint-down(xs) {
        min-height: 250px;
    }
    .arrowDown {
        background: $lightGray;
        color: $text;
        padding: 20px;
        font-size: 30px;
        width: 60px;
        height: 60px;
        border-radius: 60px;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        @include media-breakpoint-down(sm) {
            bottom: -80px;
        }
        @include media-breakpoint-down(xs) {
            bottom: -40px;
        }
    }
    .content {
        h1 {
            position: relative;
            display: table;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 20vw;
            color: $primary;
            margin-bottom: 0;
            font-family: "Montserrat", sans-serif;
            @include media-breakpoint-down(sm) {
                font-size: 30vw;
            }
            span {
                text-transform: none;
                font-size: 5vw;
                font-weight: normal;
                font-family: $fontBlack;
                color: $text;
                position: absolute;
                top: 0;
                right: -10vw;
                transform: rotate(-10deg);
                @include media-breakpoint-down(sm) {
                    font-size: 8vw;
                }
            }
        }
        h4 {
            margin-top: -30px;
            margin-left: 10px;
            font-size: 3vw;
            @include media-breakpoint-down(sm) {
                font-size: 5vw;
                margin-top: -10px;
            }
        }
    }
}
// Header Home

// Mobile Nav Container
.mobileNavContainer {
    background: white;
    min-width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1501;
    overflow-y: auto;
    // box-shadow: 0px 0px 0px 9999px rgba(0, 0, 0, 0.30);
    transition: 0.2s ease;
    &.openMobileNav {
        opacity: 1;
        left: 0%;
    }
    &.closeMobileNav {
        opacity: 0;
        left: -100%;
    }
    .header {
        background: white;
        padding: 25px;
        top: 0px;
        position: sticky;
        justify-content: space-between;
        button {
            background: $lightGray;
            border-radius: 100px;
            border: none;
            width: 40px;
            height: 40px;
            transition: 0.3s ease;
            i {
                font-size: 25px;
            }
            &:hover {
                @extend .shadow;
            }
        }
    }
    ul {
        display: flex;
        flex-direction: column;
        margin-left: 0;
        margin-top: 50px;
        padding-left: 0;
        list-style: none;
        li {
            a {
                display: table;
                width: 100%;
                padding: 15px 25px;
                color: $text;
                font-size: 18px;
                border-bottom: 1px solid $lightGray;
                transition: 0.3s ease;
                position: relative;
                &:hover {
                    background: $lightGray;
                    text-decoration: none;
                }
                &::after {
                    content: "\203A";
                    font-size: 25px;
                    display: table;
                    clear: both;
                    color: $border;
                    position: absolute;
                    top: 50%;
                    right: 25px;
                    transform: translateY(-50%);
                }
            }
        }
    }
}

.navbar-toggler {
    display: none;
}
.btnToggleMenu {
    // background: orange;
    border-radius: 100px;
    border: none;
    width: 40px;
    height: 40px;
    transition: 0.3s ease;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
        font-size: 35px;
    }
    &:hover {
        @extend .shadow;
    }
}
// Mobile Nav Container

// List step
.listStep {
    list-style: none;
    margin-left: 0;
    padding-left: 0px;
    li {
        position: relative;
        padding-left: 80px;
        padding-bottom: 2em;
        &::after {
            content: " ";
            display: table;
            width: 5px;
            height: 50%;
            border-radius: 20px;
            background: $primary;
            position: absolute;
            top: 50%;
            left: 23px;
            margin-top: 25px;
            transform: translateY(-50%);
            @extend .shadow-sm;
        }
        &:last-child {
            &::after {
                display: none;
            }
        }
        span {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background: white;
            color: $text;
            @extend .shadow;
            position: absolute;
            top: 0;
            left: 0;
            text-align: center;
        }
        h5 {
            font-weight: bold;
        }
    }
}
// List step

// Content Group
.contentGroup {
    padding-top: 70px;
    h2 {
        position: relative;
        display: table;
        font-size: 3em;
        @include media-breakpoint-down(sm) {
            font-size: 2em;
        }
        span {
            font-family: $fontBlack;
            display: table;
            position: absolute;
            top: -60px;
            font-size: 0.9em;
            right: 0;
            transform: rotate(-10deg);
        }
    }
    &.alignRight {
        text-align: right;
        h2 {
            margin-left: auto;
        }
    }
}
// Content Group

// Section About
.sectionAbout {
    background: white url("../../images/bgAboutHomepage.svg") no-repeat left center;
    min-height: 90vh;
    display: flex;
    margin: 0em 0 0em 0;
    align-items: center;
    position: relative;
    @include media-breakpoint-down(md) {
        // background: blue;
        margin-top: 60px;
        padding-top: 70px;
    }
    .circlePrimary {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        @include media-breakpoint-down(md) {
            width: 50%;
            top: 50px;
        }
    }
    .item {
        display: flex;
        z-index: 10;
        @include media-breakpoint-down(md) {
            flex-direction: column-reverse;
        }
        .content {
            padding: 0 7em;
            @include media-breakpoint-down(md) {
                padding: 0 30px;
                margin-top: -240px;
                z-index: 11;
                h2 {
                    margin-bottom: 60px;
                }
            }
        }
        .image {
            text-align: right;
            .imgAbout {
                background: white url("../../images/imageAbout.png") no-repeat center center;
                width: 700px;
                height: 440px;
                display: table;
                content: " ";
                border: 0;
                border-top-left-radius: 100px;
                border-top-right-radius: 0px;
                border-bottom-left-radius: 40px;
                border-bottom-right-radius: 0px;
                @include media-breakpoint-down(sm) {
                    height: 300px;
                }
                @include media-breakpoint-down(md) {
                    background-size: cover;
                    width: 90%;
                    margin-top: 30px;
                    height: 500px;
                    margin-left: auto;
                    position: relative;
                    &::after {
                        display: table;
                        content: "";
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        border-bottom-left-radius: 40px;
                        border-bottom-right-radius: 0px;
                        background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 81%);
                        background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 81%);
                        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 81%);
                        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
                    }
                }
            }
        }
    }
}
// Section About

// Section Do Something
.sectionDoSomething {
    min-height: 100vh;
    background: url("../../images/bgDoSomething.svg") no-repeat left center;
    background-size: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8em;
    @include media-breakpoint-down(md) {
        background-size: 90%;
        min-height: 60vh;
    }
    h2 {
        display: table;
        position: relative;
        font-family: $fontBlack;
        font-size: 5em;
        transform: rotate(-15deg);
        @include media-breakpoint-down(sm) {
            font-size: 9vw;
        }
        &::before {
            display: table;
            content: "";
            background: $primary;
            width: 50px;
            height: 50px;
            position: absolute;
            top: 0;
            left: -50px;
            border-radius: 50%;
        }
    }
}
// Section Do Something

// Section service product
.sectionServiceProduct {
    .item {
        position: relative;
        height: 700px;
        img {
            width: 100%;
            height: 700px;
            object-fit: cover;
            position: absolute;
            z-index: -1;
            transition: 0.3s ease;
        }
        .content {
            padding: 60px;
            h2 {
                font-family: $fontBlack;
                font-size: 3em;
                margin-bottom: 25px;
            }
            p {
                transition: 0.3s ease;
            }
        }
        button {
            position: absolute;
            bottom: 60px;
            left: 60px;
            transition: 0.3s ease;
        }
        &:hover {
            img {
                opacity: 0.3;
            }
            .content {
                p {
                    font-size: 25px;
                }
            }
            button {
                bottom: 100px;
            }
        }
    }
}
// Section service product

// Section Choose us
.sectionChooseUs {
    background: url("../../images/bgEllo.svg") no-repeat left center;
    background-size: inherit;
    padding: 12em 0;
    min-height: 90vh;
    @include media-breakpoint-down(sm) {
        min-height: auto;
        padding: 5em 30px;
    }
}
// Section Choose us

// Just Talk
.sectionJustTalk {
    background: $secondary url("../../images/bgHii.svg") no-repeat center left;
    background-size: contain;
    color: white;
    padding: 11em 0;
    @include media-breakpoint-down(sm) {
        padding: 7em 30px 3em 30px;
        background-size: 60%;
        background-position: -50% -30%;
    }
}
// Just Talk

// Section Portfolio
.sectionPortfolio {
    background: white url('../../images/bgPortfolio.svg') no-repeat left top;
    padding: 8em 0;

    .carousel{
        .carousel-indicators{
            // background: red;
            position: absolute;
            bottom: -3em;
            left: 0;
            li{
                background-color: gray;
                &.active{
                    background-color: $secondary;
                }
            }
        }
        .carousel-control-prev,.carousel-control-next{display: none;}
    }


    @include media-breakpoint-down(sm){
        padding: 4em 0;
    }
    h2{
        @include media-breakpoint-down(sm){
            margin-left: 20px;
            font-size: 30px;
        }
    }
    .listPortfolio {
        list-style: none;
        margin-left: 0;
        padding-left: 0;
        display: flex;
        @include media-breakpoint-down(sm){
            flex-direction: column;
        }
        li {
            flex-grow: 1;
            padding: 0 15px;
            width: 33.33%;
            @include media-breakpoint-down(sm){
                width: 100%;
            }
            .item {
                background:white url('../../images/bgCardWhite.svg') no-repeat top left;
                background-size: inherit;
                min-height: 450px;
                border-radius: 60px 20px 20px 20px;
                @extend .shadow-sm ;
                padding: 90px 30px 30px 30px;
                transition: 0.3s ease;
                margin-bottom: 30px;
                img{
                    display: table;
                    max-width: 100%;
                    margin: 0 auto 0 auto;
                }
                strong{
                    display: table;
                    margin-top: 60px;
                }
                &:hover{
                    @extend .shadow ;
                    border-radius: 40px;
                }
            }

            &:nth-child(even){
                .item{
                    background:$secondary url('../../images/bgCardDark.svg') no-repeat top left;
                    background-size: inherit;
                    color: white;
                    p{
                        opacity: 0.5;
                    }
                }
            }
        }
    }
}
// Section Portfolio

// Footer
.footer{
    background: $lightGray;
    padding: 30px 0;
    text-align: center;
}
// Footer

// Button Whatsapp mobile
.btnWhatsappMobile{
    background: $secondary;
    color: white;
    border: 1px solid $lightGray;
    border-radius: 50% 50% 50% 50%;
    width: 51px;
    height: 51px;
    position: fixed;
    bottom: 30px;
    right: 30px;
    @extend .shadow-sm;
    transition:0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    z-index: 1000;
    &:hover{
        @extend .shadow-lg;
        width: 100px;
        height: 100px;
        font-size: 35px;
        bottom: 30px;
        right: 30px;
        i{
            margin-top: -40px;
        }
    }
}
// Button Whatsapp mobile

// Button darkmode mobile
.btn-darkmodeMobile{
    margin-right: -90px;
    @include media-breakpoint-down(sm) {
        display: block;
    }
    display: none;
}
// Button darkmode mobile