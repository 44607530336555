// $default-font: "Lato", Verdana, Arial, sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap');
@font-face {
    font-family: 'BlackVosten';
    src: url('./fonts/BlackVosten.eot'); /* IE9 Compat Modes */
    src: url('./fonts/BlackVosten.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./fonts/BlackVosten.woff2') format('woff2'), /* Super Modern Browsers */
         url('./fonts/BlackVosten.woff') format('woff'), /* Pretty Modern Browsers */
         url('./fonts/BlackVosten.ttf')  format('truetype') /* Safari, Android, iOS */
        //  url('webfont.svg#svgFontName') format('svg'); 
  }