@import "./theme/scss/colors.scss";
@import "./theme/scss/fonts.scss";
@import './theme/scss/costume-bootstrap.scss'; // Cotume variable bootstrap
@import "~bootstrap/scss/bootstrap"; // From bootstrap package node
@import "./theme/scss/style.scss";
@import "./theme/scss/button.scss";
@import "./theme/scss/darkmode.scss";



