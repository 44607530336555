// Variable color Sass version
$primary:#F8C143;
$secondary:#393939;
$text:#535353;
$lightGray:#F4F4F3;
$border:#CED8DF;


$colors: (
  "blue": blue,
  "indigo": indigo,
  "purple": purple,
  "pink": pink,
  "red": red,
  "orange": orange,
  "yellow": yellow,
  "green": green,
  "teal": teal,
  "cyan": cyan,
  "white": white,
  "gray": gray,
  "gray-dark": gray,
) !default;

$theme-colors: (
    "primary": #F8C143,
    "secondary": #393939,
    "text": #535353,
    // "danger": teal,
    // "primary": yellow
);

// $link-color: #ef899e;
// $body-bg: #f0f0f0;
// $text-color: #666;